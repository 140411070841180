<template>
  <div class="box">
    <div class="header">
      <div>
        <el-form label-width="80px" :model="addFormGenerateGroup">
         <el-form-item label="选择方法:">
            <el-select v-model="addFormGenerateGroup.MethodId" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="样本总量:"
              prop="Patients"
              :rules="[
                { type: 'number', message: '必须为数字值'}
              ]"
          >
            <el-input
              placeholder="请输入内容"
              v-model.number="addFormGenerateGroup.Patients">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content">
      <div class="add-group">
        <el-button @click="addGroupDialog" :loading="loading">+新建分组</el-button>
         <!-- <el-button @click="addGenerateGroupDialog" :loading="loading">+生成入组模型</el-button> -->
      </div>
      <div style="margin-top: 10px">
        <el-table
          :data="tableData"
          :header-cell-style="{'text-align': 'center',background: 'rgb(240, 247, 253)'}"
          border
          height="300px"
          style="width: 100%">
          <el-table-column
            align="center"
            prop="GroupCode"
            label="组别号"
            width="120">
          </el-table-column>
          <el-table-column
            align="center"
            prop="Description"
            label="方案情况"
            min-width="200">
          </el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template slot-scope="scope">
              <el-link :underline="false" style="margin-right: 11px" @click="edit(scope.row)" ><i class="el-icon-edit-outline"></i>修改</el-link>
              <!-- <el-link :underline="false" @click="Delete(scope.row)"><i class="el-icon-delete"></i>删除</el-link> -->
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-bottom: 10px;">
        <Pagination
              :total="total"
              :page.sync="listQuery.page"
	            :limit.sync="listQuery.rows"
               @pagination="ProjectGroupGetPageList"
        ></Pagination>
        </div>
         <div class="btn">
          <button  @click="GenerateGroupCancel">取消</button>
          <el-button @click="GenerateGroup" :loading="loading">确定</el-button>
        </div>
      </div>
    </div>
    <div class="dialog_add">
      <el-dialog
        :visible.sync="dialogVisible"
        append-to-body
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">新增分组</span>
        </div>
        <el-form label-width="150px" :model="addFormGroup">
          <!-- <el-form-item label="组别号:" v-show="show">
            <el-input v-model="addForm.GroupCode" placeholder="请输入"></el-input>
          </el-form-item> -->
          <el-form-item label="方案情况:">
            <el-input
              type="textarea"
              :rows="1"
              placeholder="请输入内容"
              v-model="addFormGroup.Description">
            </el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <button  @click="dialogVisible = false">取消</button>
          <el-button @click="addGroup" :loading="loading">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- <div class="dialog_add">
      <el-dialog
        :visible.sync="GenerateGroupDialog"
        append-to-body
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">新增入组模型</span>
        </div>
        <el-form label-width="150px" :model="addFormGenerateGroup">
          <el-form-item label="入组总人数:"
              prop="Patients"
              :rules="[
                { type: 'number', message: '必须为数字值'}
              ]"
          >
            <el-input
              placeholder="请输入内容"
              v-model.number="addFormGenerateGroup.Patients">
            </el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <button  @click="GenerateGroupDialog = false">取消</button>
          <el-button @click="GenerateGroup" :loading="loading">确定</el-button>
        </div>
      </el-dialog>
    </div> -->
  </div>
</template>

<script>
import ProjectManagement from "../../api/ProjectManagement"
import Pagination from "../Pagination/index.vue"
export default {
name: "RandomIzation",
  data() {
    return {
      GenerateGroupDialog:false,
      addFormGenerateGroup:{
           ProjectId:this.projectId,
           Patients:'',
           MethodId:"",
      },
      addFormGroup: {
        ProjectId:this.projectId,
        // GroupCode: "",
        Description: ""
      },
      loading: false, // 加载动画，防止用户连续点击
      dialogVisible: false,
      total:0,
      listQuery: {
        Key: "",
        page: 1,
        rows: 10,
      },
      tableData: [
      ],
      options: [{
        value: '00',
        label: '简单随机化'
      }, {
        value: '01',
        label: '区组随机化'
      }, {
        value: '02',
        label: '分层区组随机化'
      }],
      value: '',
      type:1,
    }
  },
  components:{
      Pagination
  },
  props:['projectId'],
  created() {
    this.ProjectGroupGetPageList()
    console.log(this.projectId);
  },
  methods: {
    GenerateGroupCancel(){
        this.$emit("random",false)
    },
    // //打开入组模型
    // addGenerateGroupDialog(){
    //      this.GenerateGroupDialog=true
    // },
    //新增入组模型
    GenerateGroup(){
        ProjectManagement.AddProjectGroupingGroupData(this.addFormGenerateGroup).then(res=>{
                   if(res.data.Status==1){
                      this.$message({
                        message: res.data.Message,
                        type: 'success'
                    });
                     this.$emit("random",false)
                      //  this.GenerateGroupDialog=false
                   }else{
                      alert(res.data.Message)
                   }
            console.log(res);
        })
    },
    //分组列表
    ProjectGroupGetPageList(){
      const parameter={
        Key: this.listQuery.Key,
        page: this.listQuery.page,
        rows: this.listQuery.rows,
        ProjectId:this.projectId
      }
      ProjectManagement.ProjectGroupGetPageListData(parameter).then(res=>{
            if(res.data.Status==1){
                 this.total=res.data.Data.total
                 this.tableData=res.data.Data.rows
            }else{
                alert(res.data.Message)
            }
            console.log(res);
      })
    },
    addGroupDialog() { //打开新增弹框
      this.type=1
      this.dialogVisible = true
    },
    //新增分组
    addGroup() {
      if(this.type==1){
        ProjectManagement.AddProjectGroupData(this.addFormGroup).then(res=>{
               console.log(res,"新增分组");
                 if(res.data.Status==1){
                  this.tableData.push(res.data.Data)
                  this.dialogVisible=false
                     this.$message({
                      message: '保存成功',
                      type: 'success'
                    });
                 }else{
                    alert(res.data.Message)
                 }
        })
      }else{
         ProjectManagement.EditProjectGroupData(this.addFormGroup).then(res=>{
                  console.log(res,'修改');
                if(res.data.Status==1){
                    this.tableData=this.tableData.map(item=>{
                         if(item.Id==this.addFormGroup.Id){
                            item=this.addFormGroup
                         }
                         return item
                    })
                    this.dialogVisible=false
                    this.$message({
                      message: '修改成功',
                      type: 'success'
                    });
                }else{
                    alert(res.data.Message)
                 }
         })
      }
    },
    edit(row) {
       this.addFormGroup=JSON.parse(JSON.stringify(row))
       this.type=2
       this.dialogVisible=true
    },
    Delete(row) {
      console.log(row);
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/mixin.scss";
  .box {
    width: 100%;
    height: 100%;
    .header {
      display: flex;
      span {
       @include common_span;
        line-height: 40px;
      }
      ::v-deep .el-button.quxiao {
        @include common_button_plain;
        width: 102px;
      }
      ::v-deep .el-button.queding {
        @include common_button_primary;
        width: 102px;
      }
    }
    .content {
      .add-group {
        ::v-deep .el-button {
          @include common_button_primary
        }
      }
    }
  }
</style>
