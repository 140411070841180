<template>
  <div>
    <div class="add-project">
      <el-button @click="OpenCreateProject" v-if="formType == 1 && RoleType == 'D'">+创建项目</el-button>
      <el-link @click="OpenCreateProject" style="margin-right:11px;" type="primary" :underline="false"
        v-if="formType == 2"><i class="el-icon-edit"></i>修改
      </el-link>
      <el-link @click="OpenCreateProject" v-if="formType == 3" :underline="false" style="margin-right: 11px">
        <i class="el-icon-plus"></i>创建课题
      </el-link>
    </div>
    <!-- 新增项目弹框 -->
    <div class="dialog_add">
      <el-dialog :visible.sync="addprojectdialog" v-dialogDrag top="5vh" v-if="addprojectdialog">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age" v-show="formType == 1">创建项目</span>
          <span class="title-age" v-show="formType == 2">修改信息</span>
          <span class="title-age" v-show="formType == 3">创建课题</span>
        </div>
        <div class="body">
          <el-form v-if="fieldConfig.length" label-width="160px" class="customForm">
            <template v-for="item in fieldConfig">
              <template v-if="item.IsEnable">
                <!-- 文本框 -->
                <el-form-item :label="item.LabelName + ':'" required
                  v-if="['Name', 'No', 'Direction', 'Admin', 'Leader', 'LeaderTel', 'Fund'].includes(item.FieldName)">
                  <el-input v-model="projectForm[item.FieldName]" :disabled="item.FieldName === 'Admin'"
                    @change="handleChange(item.FieldName, formLabelAlign[item.FieldName])"></el-input>
                </el-form-item>
                <!-- 下拉框 -->
                <el-form-item :label="item.LabelName + ':'" required
                  v-else-if="['Type', 'Mode', 'LDept'].includes(item.FieldName)">
                  <el-select v-model="projectForm[item.FieldName]" placeholder="请选择"
                    :disabled="item.FieldName === 'LDept'">
                    <!-- 项目类型 -->
                    <template v-if="item.FieldName === 'Type'">
                      <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in ProjectTypelist"
                        :key="index"></el-option>
                    </template>
                    <!-- 项目方式 -->
                    <template v-else-if="item.FieldName === 'Mode'">
                      <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in ProjectModelist"
                        :key="index"></el-option>
                    </template>
                    <!-- 项目牵头单位 -->
                    <template v-else-if="item.FieldName === 'LDept'">
                      <el-option v-for="(item, index) in hospital" :key="index" :label="item.Name" :value="item.Id">
                      </el-option>
                    </template>
                  </el-select>
                </el-form-item>
                <!-- 单选 -->
                <el-form-item :label="item.LabelName + ':'" v-else-if="item.FieldName === 'IsRtc'">
                  <el-radio-group v-model="projectForm[item.FieldName]">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!-- 文本框 type="textarea"-->
                <el-form-item :label="item.LabelName + ':'" required
                  v-if="['ResearchContent', 'TargetContent'].includes(item.FieldName)">
                  <el-input v-model="projectForm[item.FieldName]" type="textarea"
                    @change="handleChange(item.FieldName, formLabelAlign[item.FieldName])"></el-input>
                </el-form-item>
                <el-form-item label="起止时间:" required v-if="'StartDate' === item.FieldName">
                  <div class="block">
                    <el-date-picker style="width: 170px" v-model="projectForm.StartDate" value-format="yyyy-MM-dd"
                      type="date" placeholder="选择日期">
                    </el-date-picker>
                    <span style="width: 20px">至</span>
                    <el-date-picker style="width: 170px" v-model="projectForm.EndDate" value-format="yyyy-MM-dd"
                      type="date" placeholder="选择日期">
                    </el-date-picker>
                  </div>
                </el-form-item>
              </template>
            </template>
          </el-form>
          <el-form v-else label-width="160px">
            <div class="body_left">
              <el-form-item label="项目名称" required>
                <el-input v-model="projectForm.Name"></el-input>
              </el-form-item>
              <el-form-item label="项目编号" required>
                <el-input v-model="projectForm.No"></el-input>
              </el-form-item>
              <el-form-item label="项目类型" required>
                <el-select placeholder="请选择" v-model="projectForm.Type">
                  <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in ProjectTypelist"
                    :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="项目方式" required>
                <el-select placeholder="请选择" v-model="projectForm.Mode">
                  <el-option :label="item.Name" :value="item.Code" v-for="(item, index) in ProjectModelist"
                    :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="主题方向" required>
                <el-input v-model="projectForm.Direction"></el-input>
              </el-form-item>
              <el-form-item label="项目牵头单位:" required type="textarea">
                <!-- <el-input v-model="projectForm.LDept"></el-input> -->
                <el-select v-model="projectForm.LDept" filterable remote :remote-method="remoteMethod" placeholder="请选择"
                  disabled="disabled">
                  <el-option v-for="(item, index) in hospital" :key="index" :label="item.Name" :value="item.Id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="是否RCT研究:" required>
                <el-radio-group v-model="projectForm.IsRtc">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="body_right">
              <el-form-item label="管理员" required>
                <el-input v-model="projectForm.Admin" class="input1" disabled></el-input>
              </el-form-item>
              <el-form-item label="负责人" required>
                <el-input v-model="projectForm.Leader" class="input1"></el-input>
              </el-form-item>
              <el-form-item label="手机号:" required>
                <el-input v-model="projectForm.LeaderTel" :rows="1"></el-input>
              </el-form-item>
              <el-form-item label="项目经费:" required>
                <el-input v-model="projectForm.Fund" style="width: 320px"></el-input>
                <span>万元</span>
              </el-form-item>
              <el-form-item label="起止时间:" required>
                <div class="block">
                  <el-date-picker style="width: 170px" v-model="projectForm.StartDate" value-format="yyyy-MM-dd"
                    type="date" placeholder="选择日期">
                  </el-date-picker>
                  <span style="width: 20px">至</span>
                  <el-date-picker style="width: 170px" v-model="projectForm.EndDate" value-format="yyyy-MM-dd" type="date"
                    placeholder="选择日期">
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item label="研究内容:" required>
                <el-input v-model="projectForm.ResearchContent" type="textarea" :rows="1"></el-input>
              </el-form-item>
              <el-form-item label="考核指标:" required>
                <el-input v-model="projectForm.TargetContent" type="textarea" :rows="1"></el-input>
              </el-form-item>
            </div>
          </el-form>
          <div class="btn">
            <el-button type="primary" @click="addprojectdialog = false">取消</el-button>
            <el-button type="primary" @click="CreateProject" v-show="formType == 1 || formType == 3">创建</el-button>
            <el-button @click="EdiProject" v-show="formType == 2">保存</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ProjectManagement from "@/api/ProjectManagement";
import PatientCenter from "../../api/PatientCenter"
import { local } from "../../utils/storage"
import log from "echarts/src/scale/Log";

export default {
  name: "AddProject",
  data() {
    return {
      projectForm: {
        Name: "",
        No: "", // 项目编号
        Type: "", // 项目类型
        Mode: "", // 主题方式
        Direction: "", // 主题方向
        LDept: '', // 牵头单位
        Admin: local.get("UserName"), // 管理员
        Leader: "", // 负责人
        Fund: "", // 项目经费
        StartDate: "", // 开始时间
        EndDate: "", // 结束时间
        ResearchContent: "", // 研究内容
        TargetContent: "", // 考核指标
        LeaderTel: '',
        IsRtc: false,
        RoleType: ''
      },
      addprojectdialog: false, // 创建项目弹框
      ProjectModelist: [], //项目方式数组
      ProjectTypelist: [], //项目类型数组
      hospital: [],
      fieldConfig: [] //字段列表
    }
  },
  props: ["formType", "editForm", "ParentId"],
  created() {
    this.RoleType = local.get("RoleType");

    if (this.editForm != null) {
      this.projectForm = this.editForm
    }
    PatientCenter.GetOrder({ type: 'J' }).then(res => {
      this.fieldConfig = res.data.Data
    })
  },
  methods: {
    remoteMethod(name) {
      this.Company(name)
    },
    //单位列表接口
    //修改钱
    // Company(Name){
    //        const parameter={
    //          Key:Name,
    //        }
    //        ProjectManagement.GetListCompanyData(parameter).then(res=>{
    //             if(res.data.Status==1){
    //                 this.hospital=res.data.Data
    //             }else{
    //                 alert(res.data.Message)
    //             }
    //             console.log(res,"医院");
    //        })
    // },
    //修改后
    Company(Name) {
      const parameter = {
        Key: Name,
        page: 1,
        rows: 20
      }
      ProjectManagement.GetPageListCompanyData(parameter).then(res => {
        if (res.data.Status == 1) {
          this.hospital = res.data.Data.rows;
        } else {
          alert(res.data.Message)
        }
        console.log(res, "医院");
      })
    },
    //打开创建项目弹框
    async OpenCreateProject() {
      console.log(111111);
      if (this.formType == 1 || this.formType == 3) {
        // this.Company('');
        await this.GetLeadingUnitId();
        // this.projectForm={
        //       Name: "",
        //       No: "", // 项目编号
        //       Type: "", // 项目类型
        //       Mode: "", // 主题方式
        //       Direction: "", // 主题方向
        //       LDept: "", // 牵头单位
        //       Admin: local.get("UserName"), // 管理员
        //       Leader: "", // 负责人
        //       Fund: "", // 项目经费
        //       StartDate: "", // 开始时间
        //       EndDate: "", // 结束时间
        //       ResearchContent: "", // 研究内容
        //       TargetContent: "", // 考核指标
        //       IsRtc:false,
        // }
      } else {
        this.Company(this.editForm.LDeptName)
      }
      this.addprojectdialog = true
      const ProjectMode = "XMFS"//项目方式
      PatientCenter.BaseDataBigData(ProjectMode).then(res => {
        console.log(res, "项目方式");
        if (res.data.Status == 1) {
          this.ProjectModelist = res.data.Data
        } else {
          alert(res.data.Message)
        }
      })
      const ProjectType = "XMLX"//项目类型
      PatientCenter.BaseDataBigData(ProjectType).then(res => {
        if (res.data.Status == 1) {
          this.ProjectTypelist = res.data.Data
        } else {
          alert(res.data.Message)
        }
      })
    },
    //获取牵头单位Id Name
    async GetLeadingUnitId() {
      const { data } = await ProjectManagement.GetLeadingUnitId();
      this.hospital = [];
      this.hospital.unshift({ Name: data.Name, Id: data.BindId });
      this.projectForm = {
        Name: "",
        No: "", // 项目编号
        Type: "", // 项目类型
        Mode: "", // 主题方式
        Direction: "", // 主题方向
        LDept: data.BindId, // 牵头单位
        Admin: local.get("UserName"), // 管理员
        Leader: "", // 负责人
        Fund: "", // 项目经费
        StartDate: "", // 开始时间
        EndDate: "", // 结束时间
        ResearchContent: "", // 研究内容
        TargetContent: "", // 考核指标
        IsRtc: false,
      }
    },
    //创建项目
    CreateProject() {
      let start = new Date(this.projectForm.StartDate).getTime()
      let end = new Date(this.projectForm.EndDate).getTime()
      console.log(start, end);
      if (start > end) {
        return alert("起始时间中开始时间不能大于结束时间")
      }
      const parameter = this.projectForm
      this.fieldConfig.forEach((item => {
        if (!item.IsEnable) {
          delete parameter[item.FieldName]
        }
      }))
      if(this.formType == 3){
        parameter.ParentId = this.ParentId
      }
      ProjectManagement.AddProjectData(parameter).then(res => {
        if (res.data.Status == 1) {
          this.$emit("refresh")
          this.addprojectdialog = false
          this.$message({
            message: res.data.Message,
            type: 'success'
          });
        } else {
          alert(res.data.Message)
        }
      })
    },
    //编辑项目
    EdiProject() {
      const parameter = this.projectForm
      ProjectManagement.EditProjectData(parameter).then(res => {
        console.log(res)
        if (res.data.Status == 1) {
          this.$emit("Editfather", parameter)
          this.addprojectdialog = false
        } else {
          alert(res.data.Message)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/mixin.scss";

::v-deep .el-input {
  width: 360px;
}

.add-project {
  ::v-deep .el-button {
    @include common_button_primary
  }
}

.btn {
  ::v-deep .el-button:nth-child(3) {
    width: 180px;
    border: 1px solid $bg-col-theme;
    background: $bg-col-theme;
    height: 50px;
    border-radius: 10px;
    font-size: 24px;
    color: #fff;
  }
}

.dialog_add {
  ::v-deep .el-dialog {
    width: 1080px;

    .body {
      .el-form {
        display: flex;

        .el-form-item {
          .el-input {
            @include add-size($font_size_16);
            width: 360px;
          }

          .el-textarea {
            width: 360px;

            .el-textarea__inner {
              width: 360px;
            }
          }

          .el-icon-circle-plus-outline {
            width: 30px;
            display: inline-block;
            font-size: 30px;
            vertical-align: middle;
          }

          .el-radio__label {
            @include add-size($font_size_16);
          }
        }
      }
    }
  }
}

.customForm {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
  ::v-deep .el-dialog {
    width: 96% !important;
    overflow-x: hidden !important;

    .el-dialog__body {
      padding: 30px 0 !important;
    }

    .el-input__inner {
      width: 145px !important;
    }

    .el-input {
      width: 145px !important;
    }

    .el-textarea__inner {
      width: 125px !important;
    }

    .el-form-item__label {
      width: 140px !important;
    }

    .el-form-item__content {
      margin-left: 140px !important;
    }
  }
}
</style>
